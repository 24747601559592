import React, { ReactNode } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import { MyAvatarImage } from "../../components/atoms/MyAvatarImage/MyAvatarImage"
import { BertiAvatarImage } from "../../components/atoms/MyAvatarImage/BertiAvatarImage"
import { BlogTitle } from "../../components/atoms/BlogTitle/BlogTitle"
import { TopBar } from "../../components/molecules/TopBar/TopBar"
import { Text } from "../../components/atoms/Typography/Typography"
import { Gap } from "../../components/atoms/Gap/Gap"
import { Anchor } from "../../components/atoms/Anchor/Anchor"
import { NewsletterForm } from "../../components/molecules/NewsletterForm/NewsletterForm"
import "react-mailchimp-email-signup-form/dist/esm/index.css"
import { Footer } from "../../components/molecules/Footer/Footer"

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2vh;
    box-sizing: border-box;
  }
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const LinearGradientBox = styled.div`
  width: 100vw;
  background: linear-gradient(
    124.24deg,
    #30d8fb 0%,
    #4870c4 49.48%,
    #f33191 100%
  );
`

const Hr = styled.hr`
  height: 1px;
  width: 900px;
  background-color: #e5e5e5;
  border: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Section = styled.div`
  display: flex;
  padding: 5vh 0;
`

const Speech = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

const SpeakerIdentity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 24px;
  margin-bottom: 8px;
  width: 200px;
`

const SpeakerAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  overflow: hidden;
  margin-right: 16px;
`

const SpeakerName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  font-style: italic;
  color: #696969;
`

const BlackBox = styled.div`
  width: 100vw;
  background: black;
  color: #e4e4e4;
  padding: 10vh 20vw 4vh;
  box-sizing: border-box;
`

const CenteredSection = styled(Section)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

interface AvatarProps {
  avatarImg: ReactNode
  name: string
}

const Avatar: React.FC<AvatarProps> = ({ avatarImg, name }) => (
  <SpeakerIdentity>
    <SpeakerAvatar>{avatarImg}</SpeakerAvatar>
    <SpeakerName>{name}</SpeakerName>
  </SpeakerIdentity>
)

const LucaCorbucciAvatarImage = () => {
  const corbucciAvatarData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "luca_corbucci_avatar.jpeg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={corbucciAvatarData.file.childImageSharp.fluid}
      alt="Luca Corbucci Picture"
      style={{
        width: "32px",
        height: "32px",
        position: "relative",
        display: "flex",
      }}
    />
  )
}

const StyledBlogTitle = styled(BlogTitle)`
  margin-bottom: 16px;
`

const PointerPodcast59 = () => {
  const speakerMe = (
    <Avatar
      avatarImg={<MyAvatarImage style={{ width: "32px", height: "32px" }} />}
      name="Me"
    />
  )

  const speakerCorbucci = (
    <Avatar avatarImg={<LucaCorbucciAvatarImage />} name="Luca Corbucci" />
  )

  const speakerBerti = (
    <Avatar avatarImg={<BertiAvatarImage />} name="Alessandro Berti" />
  )

  return (
    <Layout>
      <Container>
        <TopBar />
        <Content>
          <Section>
            <FlexCol>
              <StyledBlogTitle emoji="🎙" title="Pointer Podcast 59" />
              <Gap height={24} />
              <Text>
                The{" "}
                <Anchor href="https://pointerpodcast.it/">
                  Pointer Podcast
                </Anchor>{" "}
                is recorded by three Italian students of the University of Pisa,
                Luca Corbucci, Alessandro Berti, and Eugenio Paluello.
              </Text>
              <Gap height={16} />
              <Text>
                We met on Twitter, and they invited me to talk about working in
                Switzerland,{" "}
                <Anchor href="https://iterspace.com/">iterspace.com</Anchor>,
                and building an audience on Twitter.
              </Text>
              <Gap height={32} />
              <Text>
                Listen to the episode (Italian language) or read the English
                transcript 👇
              </Text>
              <Gap height={16} />
              <iframe
                allow="autoplay *; encrypted-media *; fullscreen *"
                frameBorder="0"
                height="175"
                style={{
                  width: "100%",
                  maxWidth: "660px",
                  overflow: "hidden",
                  background: "transparent",
                }}
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                src="https://embed.podcasts.apple.com/us/podcast/pointer-59-iterspace-non-semplice-side-project-con/id1465505870?i=1000528842814"
              ></iframe>
            </FlexCol>
          </Section>
          <Section style={{ flexDirection: "column" }}>
            <BlogTitle title="English transcript" />
            <Text>5 minutes read</Text>
            <Gap height={24} />
            <Speech>
              {speakerCorbucci}
              <Text>
                Luca is a web developer we discovered for a project he is
                working on.
                <br />
                As a first question, introduce yourself to our audience.
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                Hi everyone, I am a senior software engineer specialized in web
                product development.
                <br />
                I have decades of experience in the sector and have worked for
                several companies, even very different from each other.
                <br />
                For example, I started in an American multinational company.
                <br />I worked for a start-up in the cryptocurrency sector.
                <br />
                Now I work for the Lastminute Group, an OTA (Online Travel
                Agency) that sells flights and travel packages online.
                <br />
                <br />I like to grow the development teams from both technical
                and soft-skills points of view.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>We know you work in Switzerland, what is it like?</Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                My work experience is related to the area of ​​the Canton of
                Ticino.
                <br />
                In Switzerland, there are 26 cantons and 3 main languages,
                Italian, French, and German.
                <br />
                In my experience, working in Switzerland is not very different
                from Italy.
                <br />
                It depends a lot on the company and the type of projects that
                you're involved.
                <br />
                The Swiss job market is very liberal. The permanent contract
                comes easily, but it is possible to withdraw from the employment
                contract at any time, with a period of notice, by both parties.
                <br />
                Despite this, the market is stable and almost safe.
                <br />
                Salaries are competitive, but Switzerland is a costly country.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Apart from Lastminute, you have another project, which led us to
                meet you that it is iterspace. Can you tell us from which idea
                this project was born?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                The iterspace project was born 2 years ago. We are a team of two
                people, I take care of all the development while Marco Zani
                works on the UX / UI of the product.
                <br />
                <br />
                The idea of ​​iterspace was born from an idea of ​​Marco, when
                an friend asked him to review a mobile app.
                <br />
                Marco wondered how he could do it by putting together a series
                of screenshots and some textual descriptions.
                <br />
                But we thought there might be some room to improve this type of
                feedback.
                <br />
                Thus was born the idea of ​​iterspace, which is a platform with
                which you can record your screen, record your voice, and on
                which it is possible to leave precise comments and exchange
                feedback with others.
                <br />
                iterspace, therefore, allows you to iterate on digital products
                easily and quickly, and in fact, the name was not chosen by
                chance.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>
                How was the project received? Are there teams that use it?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                At the marketing level we are at the beginning, so the traffic
                reached came through personal connections or organic traffic
                that found us via LinkedIn, Twitter, or looking for a recording
                extension on the Chrome Store.
                <br />
                With these premises, the project has been very well received. We
                have feedback from enthusiastic people, but above all, we notice
                people who have been using iterspace continuously for months.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>Where are these teams located?</Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                The users come mainly from Italy and the United States, and some
                other countries, for example, we noticed a Norwegian team
                continuously coming to the platform.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Can you explain how you developed the product from a
                technological point of view?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                iterspace is made of 3 components, the web browser extension, a
                progressive web app for desktop and mobile, and the public
                website. The programming language used is JavaScript, on the
                front-end, I used the React UI library, Redux as the state
                management library, Storybook to develop the React components in
                isolation and to create a visual documentation of the
                components, and finally styled-components for the CSS-in-JS.
                <br />
                <br />
                The back-end infrastructure is based on Amazon Web Services, I
                implemented a GraphQL service supported by Lamba Functions, and
                the engine is node.js.
                <br />
                JavaScript allowed me to develop both the front-end and back-end
                using one language.
                <br />
                We use DynamoDB as the NoSQL Database.
                <br />
                We don't have one single virtual machine, as the infrastructure
                is fully serverless.
                <br />
                <br />
                This solution is very scalable and has low costs thanks to the
                pay-as-you-go pricing model. With little traffic, the fixed
                costs are minimal.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                If you had to do it again today, would you choose the same
                technologies?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                The only change I would make is in the database because with a
                relational database, I would have been more secure of the data
                structure design. I would probably use PostgreSQL.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                During the development of iterspace what was the most complex
                problem to face?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                From the product point of view, the most complex aspect we
                thought about most was the permissions part. We have created
                three concepts, the Workspace, which contains the collaborators,
                the Project, which is a folder containing Recordings.
                <br />
                It was hard to find a permissions model that would allow you to
                manage all these three entities with the level of granularity
                that users asked us, keeping the functionality simple and
                intuitive.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>
                You have a Chrome Extension, but have other browsers been
                evaluated too?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                Currently, the browser extension is available for Google Chrome
                and Microsoft Edge.
                <br />
                Both browsers are based on Chromium, so the extensions are
                automatically compatible.
                <br />
                We do not have new browsers in the roadmap because we have
                analyzed the market share of browsers.
                <br />
                Chrome is around 65% while Edge 3%, so we get to about 70% of
                the market share with both browsers.
                <br />
                So we will not invest in new browsers for the time being.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>
                Have you thought about developing a mobile or desktop app?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                Yes, we are thinking about a mobile app, which is a roadmap
                feature.
                <br />
                A native app would allow you to record the screen directly from
                our app.
                <br />
                <br />
                At the moment, iOS and Android provide native screen recording,
                but it would not be integrated with our product. You would have
                to upload the recorded video.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Are there projects similar to yours, and if so, what are the
                strengths of iterspace?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                There are competitors, one of which is very famous in the United
                States. However, it is much more oriented towards video
                messaging.
                <br />
                To record video messages, share a presentation, and communicate
                with the team asynchronously.
                <br />
                <br />
                iterspace satisfies some more technical needs, so that we would
                like to extend our recording with advanced tools.
                <br />
                For example, we want to provide a color picker, a meter, and so
                on for the UI designer.
                <br />
                This is why we place ourselves differently.
                <br />
                <br />I predict that there will be an explosion of this type of
                product because people will get used to working asynchronously
                and remotely, and therefore, you will need these tools to be
                productive.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Do you think iterspace can become something more than a side
                project?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                We strongly believe in the project. Asynchronous work will be
                increasingly present in the companies. The last year has changed
                the point of view on remote working, and I think that Work From
                Home will remain.
                <br />
                There are also undoubted advantages, companies will be able to
                look for talents worldwide and reduce fixed costs.
                <br />
                In this context, we think that iterspace can become something
                more than a side project, a sustainable business.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>
                You're looking to expand your Twitter network. Why did you
                choose Twitter, and what do you see necessary in expanding your
                network of contacts?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                I started using Twitter in 2009 to create a local network.
                <br />
                Then I quit in 2011.
                <br />
                <br />
                Twitter is a social network that I have always liked a lot.
                <br />
                <br />
                For 3 months, I started creating content for Twitter in the
                field of web development.
                <br />
                Exploring Twitter further, I found a lovely developer community
                made up of people who want to learn and support each other. For
                this reason, I believe that I will use it for a long time and
                with continuity.
                <br />
                <br />
                As to why I decided to create content on Twitter, it is because
                it makes connections between people that create opportunities
                that they could never be imagined. So I would like to create a
                network and see what happens.
              </Text>
            </Speech>

            <Speech>
              {speakerBerti}
              <Text>Why not LinkedIn?</Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                I evaluated LinkedIn, but I think it's a professional-oriented
                social with a more set and severe tone.
                <br />
                <br />I believe the content I create is more valuable on
                Twitter, where there are so many guys who want to learn how to
                develop, change their life and I think I can make an impact in
                that community.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Considering that you create content for Twitter, I have seen you
                create images with code, explanations related to JavaScript,
                have you thought about taking the same content and creating a
                blog that can be indexed better?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                I have evaluated the blog post, but I think it is a different
                medium for which the content must be adapted and formatted
                differently.
                <br />
                <br />I am planning to create an ebook that collects all the
                artboards I've made and distribute it. I think to can bring some
                values, because on Twitter the content is ephemeral.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                Do you see interest in this type of content? Has there been an
                increase in interactions with other developers?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                It really depends on how you use the social network. If you use
                it correctly, you can create relationships with other content
                creators and users.
                <br />
                I've met some interesting people who do something similar.
                <br />
                An Austrian guy developed a tool to create code snippet images
                as if it were a terminal.
                <br />
                Being an excellent tool, I became a fan of it, and we now
                support each other.
              </Text>
            </Speech>

            <Speech>
              {speakerCorbucci}
              <Text>
                We talked about working in Switzerland, iterspace, and Twitter.
                <br />
                If you had to advise a person who wants to work in Switzerland
                to a person who wants to work on a side project or create
                content for Twitter, what would they be?
              </Text>
            </Speech>

            <Speech>
              {speakerMe}
              <Text>
                I can give a general piece of advice not to get stuck in your
                comfort zone for too long but see for something new and
                exciting, no matter where you work.
                <br />
                <br />
                For the side project, it depends on the objectives.
                <br />
                A side project could be exploratory. In this case, a side
                project is a great way to learn new technologies and find out
                how to use them.
                <br />
                To create a product, I recommend starting from a concrete
                problem, which is a pain for the people, even in a small niche.
                <br />
                Propose a solution, even without developing anything, to see if
                it solves the problem.
                <br />
                If the response is positive, start developing the product.
                <br />
                In this case, you would already start with a sustainable
                business model because there are customers willing to pay.
                <br />
                <br />
                For Twitter, I recommend that you always have a cheerful voice,
                criticize but constructively.
                <br />
                I recommend looking at other profiles that have achieved good
                results, observe how they create connections with people.
                <br />
                The critical aspect is to build genuine connections with the
                people who inhabit the network and not be a mere content
                publisher.
              </Text>
            </Speech>
          </Section>
        </Content>
        <Hr />
        <CenteredSection>
          <Gap height={32} />
          <NewsletterForm />
          <Gap height={32} />
        </CenteredSection>
        <Footer />
      </Container>
    </Layout>
  )
}

export default PointerPodcast59
