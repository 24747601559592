import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export const BertiAvatarImage = () => {
  const avatarData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "alessandro_berti_avatar.jpeg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={avatarData.file.childImageSharp.fluid}
      alt="Alessandro Berti Picture"
      style={{
        width: "32px",
        height: "32px",
        position: "relative",
        display: "flex",
      }}
    />
  )
}
